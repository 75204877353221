





    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        name: "transaction",
        components: {},
        mixins:[]
    })
    export default class transaction extends Vue {
        public created():void {
            // on componenet created
        }
        public mounted():void {
            // on componenet mounted
        }
    }
